.media.screen  {

  height: calc(100vh - 40px);

  &.overlay-screen {
    height: 100%;
    display: none;
  }

  overflow: hidden;

}

.overlay-screen {

  .media.screen  {
    height: 100%;
  }

}
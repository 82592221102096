.editor-sub {

  h4.head {
    .multi(margin-top, 2);
    .multi(padding, 2, 4, 1, 4);

    display: grid;
    grid-template-columns: auto auto;

    color: @grey;
    font-size: @fontTextSmaller;
    text-transform: uppercase;

    span {

      &:last-child {
        text-align: right;
      }
    }

  }

  label,
  .label-replacement {
    // .multi(margin-bottom, 4);

    .input-description {
      .multi(margin, 2, 0);
      clear: both;
    }
  }

  .content {

    @media @tablet, @desktop {
      gap: 20px;
      box-shadow: 0 0 5px 1px @shadow;
      .multi(margin, 1, 2, 4, 2);
    }
  }

  .drag-options {

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;

    height: 45px;

  }

  &.selection,
  &.details {
    display: grid;
    overflow: hidden;

  }

  &.selection {
    grid-template-rows: minmax(40px, auto) auto;

    .content {
      display: grid;
      grid-template-rows: minmax(40px, auto) 115px;

      @media @tablet, @desktop {
        grid-template-rows: minmax(40px, auto) 45px;
      }

      .component-container {
        overflow-y: auto;
      }
    }

  }

  &.details {

    .editor.open {

      display: grid;
      grid-template-rows: max-content auto;
      gap: 5px;
      overflow: hidden;

      .multi(padding-bottom, 14);

      @media @tablet, @desktop {
        .multi(padding-bottom, 0);
      }

      .editor-module-container {

        display: grid;
        grid-template-rows: min-content 1fr;
        gap: 25px;
        overflow-y: auto;

        margin: 0;

        @media @tablet, @desktop {
          .multi(margin, 1, 2, 4, 2);
          box-shadow: 0 0 5px 1px @shadow;
        }

        &.optionsVisible {
          padding-bottom: 0;
        }

        &.noGap {
          gap: 0;
        }

        .editor-content-container {
          .multi(padding, 0, 4);
          display: grid;
          gap: 10px;

          &.noPadding {
            padding: 0;
          }
        }

        .details {
          .multi(margin, 0, 0, 6, 0);
        }


      }

    }
  }

  &.base {

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0;
      overflow-y: auto;
      margin: 0;

      box-shadow: none;
      .multi(padding, 0, 4);
      background-color: @grey2;

      @media @tablet, @desktop {
        grid-template-columns: max-content max-content max-content;
        gap: 20px;
        justify-content: center;
        .multi(margin-top, 4);
        background-color: transparent;
      }

      .overview {

        header {
          .multi(padding, 0);
          display: grid;
          width: 100%;

        }

      }

      .editor-add-editor {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }

  }

}
.loading-partial {

    position: fixed;
    display: grid;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    align-items: center;
    justify-content: center;

    .content-container {
        .multi(padding, 8);
        text-align: center;
        display: grid;

        .animation {
            .multi(margin-top, 2);

            display: grid;
            justify-self: center;


            position: relative;
            width: 150px;
            height: 10px;

            overflow: hidden;

            span {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    width: 20px;
                    height: 5px;

                    background-color: @color1;

                    animation-name: loaderAnimation;
                    animation-duration: 1.5s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                    animation-direction: alternate-reverse;

                }
            }
        }

    }

    // pointer-events: none;
}

@keyframes loaderAnimation {

    0% {
        left: 0;
    }

    100% {
        left: calc(100% - 20px);
    }

}
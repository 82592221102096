.editor-container {

  .component-container {

    display: grid;

    &:hover {

      .position-container {

        opacity: .4;

      }
    }

    .position-container {
      display: grid;
      .trans(opacity);

      & + .position-container {
        position: relative;
        width: 100%;
      }

      &:hover {
        opacity: 1;

        h3 {
          color: @textColor;
        }
      }

      .sort-element {
        transition: transform ease .1s, opacity ease .1s;

        &.sort-list-enter-active, &.sort-list-leave-active {
          opacity: .3;
        }

        &.sort-list-enter-active, &.sort-list-leave-active {
          transform: scale(.98);
        }

        &.sort-list-leave-active {
          position: absolute;
        }

      }

      .container-head {

        position: sticky;
        top: 0;
        left: 0;

        display: grid;
        grid-template-columns: 1fr 1fr;

        background-color: @white;
        .index(5);

        h3 {
          .trans(color);

          // .multi(margin, 0, 0, 0, 3);
          .multi(padding, 0, 4);

          // position: absolute;

          align-self: start;
          justify-self: start;
          height: 40px;

          // transform: translateY(-51%);

          background-color: @white;

          color: @grey;

          font-size: @fontSmall;
          line-height: 40px;

          &:nth-child(2) {

            justify-self: end;
            // .multi(margin, 0, 3, 0, 0);

          }
          // text-transform: uppercase;

        }

      }



    }

  }

}
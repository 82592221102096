.editor-options-editor {
  .index(7);

  .trans(background);

  position: fixed;
  bottom: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  align-content: center;
  width: 100%;

  height: 60px;

  background-color: @grey1;

  text-align: right;
  .multi(padding, 0, 4);

  @media @tablet, @desktop {
    position: sticky;
    top: 0;
    bottom: unset;
    align-content: start;
    width: auto;
    height: 50px;
    padding: 0;
    background-color: transparent;
    .multi(margin, 0, 2);
  }

  .left {
    display: grid;
  }

  .right {
    display: grid;
    grid-template-columns: min-content max-content;
    justify-content: end;
  }

  .state,
  button {
    .multi(padding, 0, 3);

    display: block;
    float: left;
    font-size: @fontTextSmaller;
    letter-spacing: 1px;

    text-transform: uppercase;
    .font-head;

  }

  .state {
    height: @buttonHeight;

    font-size: @fontSmall;
    line-height: @buttonLineHeight;

    .icon {
      display: grid;

      align-content: center;
      justify-content: center;
      height: 35px;

      svg {
        width: 20px;
        height: 20px;

        fill: @error;
      }
    }

  }

}
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-repeat: no-repeat;

  outline: none;

  list-style-type: none;

  text-decoration: none;

  &::-moz-focus-inner {
    border: 0;
  }
}

button {
  outline: none;

  &::-moz-focus-inner {
    border: 0;
  }
}
.input.search {

  &:focus {
    background: inherit;
  }

  .inputContainer {
    position: relative;
    display: grid;
    gap: 5px;

    .position-container {

      .add-notice {

        position: absolute;
        right: 0;

        bottom: 7px;

        margin: auto;

      }

    }

  }
}
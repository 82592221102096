label, .label-replacement {

  span.label {
    .font-normal;
    .multi(padding, 1, 0, 0, 0);

    color: @textColor;

    font-size: @fontTextSmaller;

    text-transform: uppercase;
  }
}

.editor-history {

    header {
        display: grid;
        grid-template-columns: 60% 40%;

        h3 {
            text-align: right;
        }
    }

    .details {
        .multi(padding, 2, 4);
    }
    
    .controls {
        .multi(padding, 2, 4);
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        a {
            text-align: center
        }
    }

    .options {
        .multi(padding, 0, 4);
        li {
            
            a {
                text-transform: uppercase;
                .multi(margin-bottom, 2);
            }

            p {
                font-size: @fontTextSmaller;
                .multi(margin-top, 1);
            }
            
            .multi(margin-bottom, 8);
        }
    }
}
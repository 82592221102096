.pagenator {

  &.full {

    table {
      display: grid;

      &.content {
        .multi(padding-bottom, 16);
      }

      &.isSticky {
        position: sticky;
        top: 0;
        border-spacing: 0;
        background-color: @grey2;
      }

      tr {

        td {
          display: grid;
          align-content: center;
          height: 75px;
          text-align: center;

          @media @desktop {

            &.info {
              grid-column-start: auto;
            }

            &.last {
              grid-column-start: auto;
            }

            &.optional {
              display: block;
            }

          }

          &.optional {
            display: none;
          }

          &.last {
            grid-column-start: end;
          }

          &.action {

            grid-template-columns: 1fr;

            a {
              display: grid;
              align-content: center;
              width: 100%;
              height: 75px;
            }

            &.hasDescription {

              a {
                gap: 3px;
                line-height: 16px;
                white-space: nowrap;

                .description, .name {
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                .description {
                  color: @color1;
                }
                .name {
                  color: @grey;
                }
              }
            }

            &.hasAttribute {

              a {

                div {
                  display: grid;
                  grid-template-columns: max-content 1fr;
                  gap: 5px;

                }
              }
            }

          }

          // &.attributes {

          //   span {
          //     padding: 3px;
          //     background-color: @color1;
          //     color: @white;
          //   }
          // }

        }

      }

      thead {

        tr {

          td {
            height: 30px;
          }
        }
      }

      tbody {

        tr {

          &:hover {

            td {
              background-color: @grey1;
            }
          }

        }

        td {

          a {
            padding: 0;

            sup {
              display: inline-block;
              padding-right: 2px;
              padding-left: 2px;
              color: @white;
              line-height: 20px;
              .multi(margin-left, 1);

              &.missing {
                background-color: @error;
              }

              &.new {
                background-color: @color1;
              }

            }

          }
        }
      }
    }

    table,
    .stats {

      width: 100%;
      max-width: 100%;
    }

    header.main {

      .basePadding;
      .multi(padding-top, 4);
      .multi(padding-bottom, 4);

      display: grid;
      grid-template-columns: 1fr 1fr;

      .stats {
        display: grid;

        &.upload {
          display: grid;
          grid-template-columns: max-content 1fr;

          h3 {
            text-align: right;
          }
        }

        h3 {
          font-size: @fontTextSmaller;
          text-transform: uppercase;
        }
      }

    }

    table {
      display: grid;
      width: 100%;
      margin: 0;
      padding: 0;

      &.isSticky {
        position: sticky;
        top: 40px;
        left: 0;
        border-spacing: 0;
        background-color: @grey2;
      }

      thead,
      tbody {

        tr {
          display: grid;

          gap: 5px;
          width: 100%;

          .basePadding;

          @media @tablet, @desktop {
            gap: 40px;
          }

          &:hover {
            background-color: @grey1;
          }

          td {

            &:last-child {
              justify-content: end;
              text-align: right;
            }

            &:first-child {
              justify-content: start;
              text-align: left;
            }

            &.center {
              justify-items: center;
            }

            // .multi(padding-top, 2);
            // .multi(padding-bottom, 2);

            // &.fullHeight {
            .multi(padding-top, 0);
            .multi(padding-bottom, 0);
            // }

            &:first-child {
              .multi(padding-left, 0);
            }

            &:last-child {
              .multi(padding-right, 0);
            }
          }
        }
      }

      thead {

        tr {

          td {
            height: 30px;
          }
        }
      }

      tbody {

        tr {

          td {
            height: 75px;
          }

          &:hover {

            td {
              background-color: @grey1;
            }
          }

        }
      }

      tbody {

        tr {

          td {

            span {

              &.name, &.fileInfos {
                display: grid;
                word-break: break-all;
                align-content: center;
  
              }

              &.fileInfos {
                .multi(margin-top, 1);
                color: @grey;
              }
            }


          }
        }
      }
    }
  }
}

.screen.started &,
.screen.resolving &,
.screen.done & {

  .pagenator.full table.data-switch {

    &.started,
    &.resolving,
    &.loading {
      opacity: 1;
      transform: none;
    }

  }

}
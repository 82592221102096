.content-group-trigger {
    text-transform: uppercase;
    cursor: pointer;

    display: block;
    width: 100%;
    text-align: left;
    position: relative;

    color: @textColor;
    font-size: @fontText;

    line-height: 30px;
    // .multi(margin-bottom, 2);

    .font-bold;

    &.open {

        .multi(margin-bottom, 3);
    }
}
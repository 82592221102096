.list-editor {

  .list-item-options {

    .button-container {

      .multi(padding-right, 2);

      .index(5);

      position: absolute;

      display: grid;
      grid-template-rows: 30px;
      grid-template-columns: 30px 30px 30px;
      align-self: center;

      justify-self: end;

      background-color: @white;

      &.imageMode {

        align-self: start;
        .multi(padding-right, 4);
      }

      button {
        display: grid;
        height: 30px;

        background-size: 10px;
      }

    }


  }
  
  .add-element-container {
      .multi(margin, 0, 4);

      @media @tablet, @desktop {
        margin: 0;
      }

      button {
    
        &.add-element {
          display: grid;
          width: 100%;
          height: 45px;
          font-size: @fontTextSmaller;
    
          line-height: 45px;
          text-align: center;
          text-transform: uppercase;
    
          .font-head;
        }
    
      }

  }


}
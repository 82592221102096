.trigger.close {
    position: relative;

    width     : 20px;
    height    : 30px;

    text-align: center;

    &:after {
      display: none;
    }

    span {
      .trans(transform);

      position        : absolute;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;

      display         : block;

      width           : 20px;
      height          : 2px;
      margin          : auto;

      transform       : rotate(45deg);

      background-color: @white;

      &:first-child {
        display: none;
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
a {
  color: @link;
  .font-normal;
  .trans(color);

  &:hover, &:active {
    color: @linkHover;
  }

  &.linkButton, &.callToAction  {

    .font-head;
    .multi(padding, 1, 0);
    .multi(margin, 0, 2);

    &:after {
      display: none;
    }

    background-color: transparent;
    color: @white;

  }

  &.icon {
    display: grid;
    width: @buttonHeight;
    height: @buttonHeight;
    justify-items: center;
    .basicHover;
  }

  &.linkButton {
  }

  &.callToAction {

    padding: 10px 15px 8px 15px;

    background-color: transparent;
    border: solid 2px @white;
    text-transform: uppercase;
    color: @white;

    transition: border @aTime @aType, text @aTime @aType;

    &:hover {
      border-color: @color1;
    }

  }

  &.scopeMissing {
      color: @textColor;
      opacity: .8;
      pointer-events: none;
  }

}

.screen.editor-screen {
  display: grid;
  grid-template-columns: calc(100vw - 40px) calc(100vw - 40px);
  overflow: hidden;
  width: 200vw;

  height: 100vh;

  .content-left-container, .content-right-container {
    position: relative;
    transition: transform @aTimeFast @aType;

    .mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      width: 100%;
      height: 100%;
      background-color: @white;
      opacity: .6;
      .index(8);
      .trans(opacity);
      cursor: pointer;
    }
  }

  .content-left-container {

    .mobile-overlay {
      pointer-events: none;
      opacity: 0;
    }
  }

  .content-right-container {

    .mobile-overlay {
      pointer-events: all;
    }
  }

  &.showEditor {

    .content-left-container, .content-right-container {
      transform: translateX(calc(-100% + 40px));
    }

    .content-left-container {

      .mobile-overlay {
        opacity: .6;
        pointer-events: all;
      }
    }

    .content-right-container {

      .mobile-overlay {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  @media @tablet, @desktop {
    grid-template-columns: auto 380px;
    gap: 0 20px;
    width: unset;

    .toggleEditorButton {
      display: none;
    }

    &.showEditor {

      .content-left-container, .content-right-container {
        transform: translateX(0);
      }
    }

    .content-left-container, .content-right-container {
      .mobile-overlay {
        display: none;
      }
    }
  }


  .editor-container {

    &.base {
      display: grid;
      grid-template-rows: max-content minmax(200px, auto) max-content;

      overflow: hidden;

      &.details {
        grid-template-rows: minmax(200px, auto) max-content;
      }
    }

    &.top {

      height: 35px;

      .index(6);

      .top-left {
        width: 100%;
      }
    }

  }

  .content-left-container, .content-right-container {
    height: calc(100vh - 40px);
  }

  .content-left-container {
    position: relative;
    display: grid;
    grid-template-rows: 35px 1fr;

    .top {
      grid-template-columns: 1fr;
      .multi(padding, 0, 4);
      background-color: @grey1;

      @media @tablet, @desktop {
        .multi(padding, 0);
        background-color: transparent;
      }
    }

    .iframe-container {

      display: grid;
      overflow: auto;

      iframe {

        display: block;
        align-self: center;
        width: 100%;
        height: 100%;

        margin: auto;
        border: 0;

        transition: width .3s ease, height .3s ease;

        &.mobile {
          width: 360px;
          height: 640px;
        }

        &.tablet {
          width: 768px;
          height: 1024px;
        }

        &.desktop {
          width: 1280px;
          height: 768px;
        }

        &.hd {
          width: 1920px;
          height: 1280px;
        }

        &.mobile, &.tablet, &.desktop, &.hd {
          border: solid 2px @grey;
          box-shadow: 0 0 5px @grey;
        }

      }
    }

    // .media.screen {
    //     grid-column-start: 1;
    //     background-color: @white;
    // }

  }

  &.historyMode {

    .content-left-container {

      .iframe-container {

        position: relative;

        iframe {

          &.current {
            position: absolute;
            opacity: 0;
            pointer-events: none;

            .trans(opacity);

            &.visible {
              opacity: 1;
              pointer-events: all;
            }
          }

        }
      }

    }
  }

  .content-right-container {
    display: grid;
    grid-template-rows: auto;
    align-content: start;

    @media @tablet, @desktop {
      max-width: 360px;
    }
  }



}
button {

  position: relative;

  display: inline-block;
  overflow: hidden;
  border: 0;
  border: none;
  background: transparent;
  background: none;

  color: @textColor;
  cursor: pointer;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &.scopeMissing {
    color: @textColor;
    opacity: .8;
    pointer-events: none;
  }

  &:active {
    border: none;
    background: #FFF;
  }

  &:focus {
    background: transparent;
    outline: 0;
  }

  &.doublecheck {
    color: @error;

    .multi(padding, 1, 0, 1, 0);
    .lineHeight(@fontText);
    font-size: @fontText;

    &::after {
      background-color: @error;
    }

    &.showCheck {
      background-color: @error;
      color: @white;

      &:hover {
        background-color: @error;
        color: @white;
      }
    }

  }

  &.save {

    &.init {

      span {

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;

          width: 20px;
          height: 20px;

          background-color: @white;

          animation-name: border;
          animation-duration: 2s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;

        }
      }

    }

    &.success {
      background-color: @success;
      color: @white;
    }

  }


}

@keyframes border {

  0% {
    bottom: calc(100% - 3px);
    left: -17px;
  }

  25% {
    bottom: calc(100% - 3px);
    left: calc(100% - 3px);
  }

  50% {
    bottom: -17px;
    left: calc(100% - 3px);
  }

  75% {
    bottom: -17px;
    left: -17px;
  }

  100% {
    bottom: calc(100% - 3px);
    left: -17px;
  }

}

button,
.button {
  .trans(background);
  display: inline-block;

  &.colorScheme1 {
    background-color: @color3;
    color: @white;

    &:hover {
      background-color: @color1;
    }

  }

  &.colorScheme2 {
    background-color: @grey1;
    color: @textColor;

    &:hover {
      background-color: @color2;
    }

    &.router-link-active {
      background-color: @grey;

      &:hover {
        background-color: @grey1;
        color: @textColor;
      }

    }

  }

  &.hoverSchema1 {

    &:hover {
      background-color: @grey1;
      color: @textColor;
    }

  }

  &.colorScheme1,
  &.colorScheme2 {

    &.save {

      &.init {
        background-color: transparent;
        color: @color1;

        span {

          &:after {
            background-color: @color1;
          }
        }
      }
    }

    &.success {
      background-color: @success;
      color: @white;
    }

    &.error {
      background-color: @error;
      color: @white;

    }

    &.success,
    &.error {
      .trans(opacity);

      &:hover {
        opacity: .7;
      }
    }

  }

  &.base {
    height: @buttonHeight;
    font-size: @fontTextSmaller;

    line-height: @buttonLineHeight;

    text-transform: uppercase;

    .font-head;
    .multi(padding, 0, 3);


  }

  &.icon {
    width: 35px;
    padding: 0;
    text-indent: -9999em;

    .icon {

      display: grid;
      align-content: center;

      justify-content: center;

      height: 35px;

      svg {
        width: 15px;

        height: 15px;

      }

    }



  }

  &.full {
    width: 100%;
    text-align: center;
  }

  &.inactive {
    opacity: .5;
    pointer-events: none;
  }

  &:disabled {
    background-color: @grey1;
    color: @grey;

    &:hover {
      background-color: @grey1;
    }
  }


}

button,
.button {

  margin: 0;

  border: none;
  background: transparent;

  outline: none;

  white-space: nowrap;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  .trans(background);

  &.base {
    height: @buttonHeight;
    font-size: @fontTextSmaller;

    line-height: @buttonLineHeight;

    text-transform: uppercase;

    .font-head;
    .multi(padding, 0, 3);

    &.extendedHeight {
      height: 45px;
      line-height: 45px;
    }
  }

  &.colorScheme1 {
    background-color: @color3;
    color: @white;

    &:hover {
      background-color: @color1;
    }

  }

  &.colorScheme2 {
    background-color: @grey1;
    color: @textColor;

    &:hover {
      background-color: @color2;
    }

    &.router-link-active {
      background-color: @grey;

      &:hover {
        background-color: @grey1;
        color: @textColor;
      }

    }

  }

  &.transparent {
    padding: 0;
    background-color: transparent;
    color: @textColor;
    .trans(color);

    &:hover {
      color: @color1;
    }
  }

  &.hoverSchema1 {

    &:hover {
      background-color: @grey1;
      color: @textColor;
    }

  }

  &.colorScheme1,
  &.colorScheme2 {

    &.save {

      &.init {
        background-color: transparent;
        color: @color1;

        span {

          &:after {
            background-color: @color1;
          }
        }
      }
    }

    &.success {
      background-color: @success;
      color: @white;
    }

  }

  &.clean {
    padding: 0;
  }

  &.icon {
    width: 35px;
    padding: 0;

    .icon-partial {
      width: 35px;
      height: 35px;
    }
  }

  &.full {
    width: 100%;
    text-align: center;
  }

  &.transparent {
    padding: 0;
    background-color: transparent;
    color: @textColor;
    .trans(color);

    &:hover {
      color: @color1;
    }

    &.buttonIcon {

      .icon-partial {

        svg {
          .trans(stroke);
        }
      }

      &:hover {

        .icon-partial {

          svg {
            stroke: @grey;
          }
        }
      }
    }

    &.fontNormal {
      .font-normal;
    }

    &.success {
      color: @success;
      .font-head;
    }

  }

  &:disabled {
    background-color: @grey;
    pointer-events: none;
    user-select: none;
  }

  &.callToAction {
    .base;
    .colorScheme1;
    .trans(background);
  }

  &.scrollButton {
    display: grid;
    text-align: center;
    .font-head;

    .icon {
      justify-self: center;
      margin-top: 5px;
    }
  }


}
.quickmenu {



}


@keyframes upload {
    0% {
        transform: translateY(75%);
    }

    100% {
        transform: translateY(-75%);
    }
}
.attribute {

  padding: 0 5px;
  background-color: @grey;
  color: @white;
  font-size: @fontTextSmaller;
  text-transform: uppercase;
  
  &.color1 {
    background-color: @color1;
  }

  &.red {
    background-color: @error;
  }

  .font-bold;

  .text-full {
    display: none;
  }

  @media @tablet, @desktop {

    .text-short {
      display: none;
    }

    .text-full {
      display: grid;
    }
  }


}
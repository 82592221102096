.media.screen {

  .scroll-container {
    overflow-y: auto;
    padding: 0;
  }

  .dropfield {
    display: flex;
    flex-wrap: wrap;

    overflow: hidden;

    .multi(padding, 0, 8, 20, 8);

    &:after {
      content: '';
      flex-grow: 1e4;
      min-width: 20%;
    }

    .item {
      position: relative;
      display: grid;
      overflow: hidden;
      margin: 2px;
      background-color: @background;
      cursor: pointer;

      .trans(opacity);

      &::after {
        content: '';
        flex-grow: 999999999;
      }

      &:hover {

        opacity: .8;

      }

      .video-placeholder {
        position: absolute;
        display: grid;
        align-content: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: @white;
        .index(1);
        .font-head;
      }

      &.folder {

        display: grid;

        .icon-partial {

          position: absolute;

          display: grid;

          align-self: center;
          justify-self: center;

          .index(1)
        }
      }

      i {
        display: block;
      }

      .infos {
        position: absolute;
        display: grid;

        grid-template-rows: 1fr 1fr;
        align-self: center;
        justify-self: center;

        width: 100%;
        height: 100%;

        .typeSize {
          display: grid;
          grid-template-columns: max-content max-content;

          align-self: end;
          justify-content: end;
          width: 100%;

          span {
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
          }
        }

        .filename {
          display: grid;
          grid-template-rows: min-content;
          justify-self: start;

          span {
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
          }
        }

        span {
          display: inline-block;
          background-color: @color1;
          color: @white;

          .multi(padding, 1, 2);

          &:last-child {
            justify-self: end;
          }
        }
      }

      .selectButton {
        position: absolute;
        top: 5px;
        left: 5px;
      }

      &.image {

        opacity: 1;
        background-color: @white;

        transition: background @aTime @aType;

        &:hover {
          background-color: rgba(63, 45, 86, .8)
        }
      }

      picture {
        transition: opacity .5s @aType;

        transition-delay: .5s;
        mix-blend-mode: multiply;

        img {
          position: absolute;
          top: 0;
          vertical-align: bottom;
          width: 100%;

        }

        &.svg {

          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            min-width: 40px;

            max-width: 50%;
            margin: auto;

          }
        }

      }

      video,
      .placeholder {
        position: absolute;
        top: 0;
        vertical-align: bottom;
        object-fit: cover;
        overflow: hidden;
        width: 100%;

        height: 100%;

      }

    }
  }

}
label,
.label-parent-container,
.label-replacement {

  span.required {
    .font-normal;
    .multi(padding, 1, 0, 0, 0);

    color: @textColor;

    font-size: @fontTextSmaller;

    text-align: right;
    text-transform: uppercase;
  }
}
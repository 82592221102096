.actions-module {
    .single-action {
        display: grid;
        .multi(padding, 2, 4);
        gap: 10px;

        &:hover {
            background-color: @grey1;
        }

        .action-head {
            display: grid;
            grid-template-columns: auto auto;
            text-transform: uppercase;

            .state {

                .trans(color);

                &.started {
                    color: @textColor;
                }
    
                &.success {
                    color: @success;
                }
    
                &.error {
                    color: @error;
                }

                text-align: right;
                
                .font-bold;
            }

        }

        p {
            font-size: @fontTextSmaller;
        }
    }
}
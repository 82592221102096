table {
  width: 100%;
  border-spacing: 0;

  &.isSticky {
    background-color: @grey2;
  }

  &.inline {

    display: grid;

    thead,
    tbody {
      display: grid;
      gap: 5px;

      tr {

        display: grid;
        padding: 0;

        &:hover {
          background-color: transparent;

          td {
            background-color: transparent;
          }
        }

        td {
          height: auto;
          padding: 0;

          &:first-child, &:last-child {
            padding: 0;
          }
        }
      }
    }
  }

  thead,
  tbody {

    tr {
      width: 100%;

      .multi(padding-left, 8);
      .multi(padding-right, 8);

      &:hover {
        background-color: @grey1;
      }

      td, th {

        &:last-child {
          text-align: right;
        }

        &:first-child {
          text-align: left;
        }

        .multi(padding-top, 2);
        .multi(padding-bottom, 2);

        &.fullHeight {
          .multi(padding-top, 0);
          .multi(padding-bottom, 0);

          .full {
            display: block;

            line-height: 60px;
          }

        }

        &:first-child {
          .multi(padding-left, 8);
        }

        &:last-child {
          .multi(padding-right, 8);
        }
      }
    }
  }

  thead {

    tr {

      td {
        height: 40px;
      }
    }
  }

  tbody {

    tr {

      td {
        height: 60px;
      }

      &:hover {

        td {
          background-color: @grey1;
        }
      }

    }
  }
}
h1 {

  font-size: @headline_XS;
  line-height: @headlineLineHeight_XS;

  text-transform: uppercase;

  @media @tablet, @desktop {
    font-size: @headline_S;
    line-height: @headlineLineHeight_S;
  }

}

h2 {
  color: @color3;
  font-size: 1.9em;
  line-height: 1.4em;
  letter-spacing: .5px;
}

h1,
h2 {
  .font-head;
  color: @color1;
}

h3,
h4 {
  .font-normal;
  font-size: @fontText;
}

h3 {
  color: @black;
  .font-bold;
}

h5 {
  color: @grey;
  font-size: .8em;
  text-transform: uppercase;
  .font-head;
}

@font-face {
  font-family: 'Nexa-Heavy';
  src: url('/source/editor/fonts/Nexa-Heavy.woff');
}
@font-face {
  font-family: 'NexaText-Bold';
  src: url('/source/editor/fonts/NexaText-Bold.woff');
}
@font-face {
  font-family: 'Nexa';
  src: url('/source/editor/fonts/Nexa.woff');
}
@font-face {
  font-family: 'FiraMono';
  src: url('/source/editor/fonts/FiraMono-Regular.woff');
}
.font-light {
  font-family: Nexa;
}
.font-normal {
  font-family: 'Nexa';
  letter-spacing: 0.2px;
}
.font-bold {
  font-family: 'NexaText-Bold';
}
.font-head {
  font-family: 'Nexa-Heavy';
}
.font-mono {
  font-family: FiraMono;
}
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  outline: none;
  list-style-type: none;
  text-decoration: none;
}
*::-moz-focus-inner {
  border: 0;
}
button {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
.formBorder {
  border: 0;
  border-bottom: 1px solid #3f2d56;
}
.basicHover {
  transition: opacity 0.3s ease-out;
}
.basicHover:hover {
  opacity: 0.5;
}
.basePadding {
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .basePadding {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.basePaddingWithTop {
  padding: 20px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .basePaddingWithTop {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.list-editor .list-item-options .button-container {
  padding-right: 10px;
  z-index: 500;
  position: absolute;
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 30px 30px 30px;
  align-self: center;
  justify-self: end;
  background-color: #FFFFFF;
}
.list-editor .list-item-options .button-container.imageMode {
  align-self: start;
  padding-right: 20px;
}
.list-editor .list-item-options .button-container button {
  display: grid;
  height: 30px;
  background-size: 10px;
}
.list-editor .add-element-container {
  margin: 0px 20px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .list-editor .add-element-container {
    margin: 0;
  }
}
.list-editor .add-element-container button.add-element {
  display: grid;
  width: 100%;
  height: 45px;
  font-size: 12px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Nexa-Heavy';
}
button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 0;
  border: none;
  background: transparent;
  background: none;
  color: #555;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
button.scopeMissing {
  color: #555;
  opacity: 0.8;
  pointer-events: none;
}
button:active {
  border: none;
  background: #FFF;
}
button:focus {
  background: transparent;
  outline: 0;
}
button.doublecheck {
  color: #EA624F;
  padding: 5px 0px 5px 0px;
  line-height: 19.5px;
  font-size: 15px;
}
button.doublecheck::after {
  background-color: #EA624F;
}
button.doublecheck.showCheck {
  background-color: #EA624F;
  color: #FFFFFF;
}
button.doublecheck.showCheck:hover {
  background-color: #EA624F;
  color: #FFFFFF;
}
button.save.init span:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  animation-name: border;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
button.save.success {
  background-color: #5A8C99;
  color: #FFFFFF;
}
@keyframes border {
  0% {
    bottom: calc(100% - 3px);
    left: -17px;
  }
  25% {
    bottom: calc(100% - 3px);
    left: calc(100% - 3px);
  }
  50% {
    bottom: -17px;
    left: calc(100% - 3px);
  }
  75% {
    bottom: -17px;
    left: -17px;
  }
  100% {
    bottom: calc(100% - 3px);
    left: -17px;
  }
}
button,
.button {
  transition: background 0.3s ease-out;
  display: inline-block;
}
button.colorScheme1,
.button.colorScheme1 {
  background-color: #533474;
  color: #FFFFFF;
}
button.colorScheme1:hover,
.button.colorScheme1:hover {
  background-color: #3f2d56;
}
button.colorScheme2,
.button.colorScheme2 {
  background-color: #F6F6F6;
  color: #555;
}
button.colorScheme2:hover,
.button.colorScheme2:hover {
  background-color: #e0e5e5;
}
button.colorScheme2.router-link-active,
.button.colorScheme2.router-link-active {
  background-color: #CCCCCC;
}
button.colorScheme2.router-link-active:hover,
.button.colorScheme2.router-link-active:hover {
  background-color: #F6F6F6;
  color: #555;
}
button.hoverSchema1:hover,
.button.hoverSchema1:hover {
  background-color: #F6F6F6;
  color: #555;
}
button.colorScheme1.save.init,
.button.colorScheme1.save.init,
button.colorScheme2.save.init,
.button.colorScheme2.save.init {
  background-color: transparent;
  color: #3f2d56;
}
button.colorScheme1.save.init span:after,
.button.colorScheme1.save.init span:after,
button.colorScheme2.save.init span:after,
.button.colorScheme2.save.init span:after {
  background-color: #3f2d56;
}
button.colorScheme1.success,
.button.colorScheme1.success,
button.colorScheme2.success,
.button.colorScheme2.success {
  background-color: #5A8C99;
  color: #FFFFFF;
}
button.colorScheme1.error,
.button.colorScheme1.error,
button.colorScheme2.error,
.button.colorScheme2.error {
  background-color: #EA624F;
  color: #FFFFFF;
}
button.colorScheme1.success,
.button.colorScheme1.success,
button.colorScheme2.success,
.button.colorScheme2.success,
button.colorScheme1.error,
.button.colorScheme1.error,
button.colorScheme2.error,
.button.colorScheme2.error {
  transition: opacity 0.3s ease-out;
}
button.colorScheme1.success:hover,
.button.colorScheme1.success:hover,
button.colorScheme2.success:hover,
.button.colorScheme2.success:hover,
button.colorScheme1.error:hover,
.button.colorScheme1.error:hover,
button.colorScheme2.error:hover,
.button.colorScheme2.error:hover {
  opacity: 0.7;
}
button.base,
.button.base {
  height: 35px;
  font-size: 12px;
  line-height: 37px;
  text-transform: uppercase;
  font-family: 'Nexa-Heavy';
  padding: 0px 15px;
}
button.icon,
.button.icon {
  width: 35px;
  padding: 0;
  text-indent: -9999em;
}
button.icon .icon,
.button.icon .icon {
  display: grid;
  align-content: center;
  justify-content: center;
  height: 35px;
}
button.icon .icon svg,
.button.icon .icon svg {
  width: 15px;
  height: 15px;
}
button.full,
.button.full {
  width: 100%;
  text-align: center;
}
button.inactive,
.button.inactive {
  opacity: 0.5;
  pointer-events: none;
}
button:disabled,
.button:disabled {
  background-color: #F6F6F6;
  color: #CCCCCC;
}
button:disabled:hover,
.button:disabled:hover {
  background-color: #F6F6F6;
}
button,
.button {
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: background 0.3s ease-out;
}
button.base,
.button.base {
  height: 35px;
  font-size: 12px;
  line-height: 37px;
  text-transform: uppercase;
  font-family: 'Nexa-Heavy';
  padding: 0px 15px;
}
button.base.extendedHeight,
.button.base.extendedHeight {
  height: 45px;
  line-height: 45px;
}
button.colorScheme1,
.button.colorScheme1 {
  background-color: #533474;
  color: #FFFFFF;
}
button.colorScheme1:hover,
.button.colorScheme1:hover {
  background-color: #3f2d56;
}
button.colorScheme2,
.button.colorScheme2 {
  background-color: #F6F6F6;
  color: #555;
}
button.colorScheme2:hover,
.button.colorScheme2:hover {
  background-color: #e0e5e5;
}
button.colorScheme2.router-link-active,
.button.colorScheme2.router-link-active {
  background-color: #CCCCCC;
}
button.colorScheme2.router-link-active:hover,
.button.colorScheme2.router-link-active:hover {
  background-color: #F6F6F6;
  color: #555;
}
button.transparent,
.button.transparent {
  padding: 0;
  background-color: transparent;
  color: #555;
  transition: color 0.3s ease-out;
}
button.transparent:hover,
.button.transparent:hover {
  color: #3f2d56;
}
button.hoverSchema1:hover,
.button.hoverSchema1:hover {
  background-color: #F6F6F6;
  color: #555;
}
button.colorScheme1.save.init,
.button.colorScheme1.save.init,
button.colorScheme2.save.init,
.button.colorScheme2.save.init {
  background-color: transparent;
  color: #3f2d56;
}
button.colorScheme1.save.init span:after,
.button.colorScheme1.save.init span:after,
button.colorScheme2.save.init span:after,
.button.colorScheme2.save.init span:after {
  background-color: #3f2d56;
}
button.colorScheme1.success,
.button.colorScheme1.success,
button.colorScheme2.success,
.button.colorScheme2.success {
  background-color: #5A8C99;
  color: #FFFFFF;
}
button.clean,
.button.clean {
  padding: 0;
}
button.icon,
.button.icon {
  width: 35px;
  padding: 0;
}
button.icon .icon-partial,
.button.icon .icon-partial {
  width: 35px;
  height: 35px;
}
button.full,
.button.full {
  width: 100%;
  text-align: center;
}
button.transparent,
.button.transparent {
  padding: 0;
  background-color: transparent;
  color: #555;
  transition: color 0.3s ease-out;
}
button.transparent:hover,
.button.transparent:hover {
  color: #3f2d56;
}
button.transparent.buttonIcon .icon-partial svg,
.button.transparent.buttonIcon .icon-partial svg {
  transition: stroke 0.3s ease-out;
}
button.transparent.buttonIcon:hover .icon-partial svg,
.button.transparent.buttonIcon:hover .icon-partial svg {
  stroke: #CCCCCC;
}
button.transparent.fontNormal,
.button.transparent.fontNormal {
  font-family: 'Nexa';
  letter-spacing: 0.2px;
}
button.transparent.success,
.button.transparent.success {
  color: #5A8C99;
  font-family: 'Nexa-Heavy';
}
button:disabled,
.button:disabled {
  background-color: #CCCCCC;
  pointer-events: none;
  user-select: none;
}
button.callToAction,
.button.callToAction {
  height: 35px;
  font-size: 12px;
  line-height: 37px;
  text-transform: uppercase;
  font-family: 'Nexa-Heavy';
  padding: 0px 15px;
  background-color: #533474;
  color: #FFFFFF;
  transition: background 0.3s ease-out;
}
button.callToAction.extendedHeight,
.button.callToAction.extendedHeight {
  height: 45px;
  line-height: 45px;
}
button.callToAction:hover,
.button.callToAction:hover {
  background-color: #3f2d56;
}
button.callToAction.save.init,
.button.callToAction.save.init {
  background-color: transparent;
  color: #3f2d56;
}
button.callToAction.save.init span:after,
.button.callToAction.save.init span:after {
  background-color: #3f2d56;
}
button.callToAction.success,
.button.callToAction.success {
  background-color: #5A8C99;
  color: #FFFFFF;
}
button.scrollButton,
.button.scrollButton {
  display: grid;
  text-align: center;
  font-family: 'Nexa-Heavy';
}
button.scrollButton .icon,
.button.scrollButton .icon {
  justify-self: center;
  margin-top: 5px;
}
a {
  color: #3f2d56;
  font-family: 'Nexa';
  letter-spacing: 0.2px;
  transition: color 0.3s ease-out;
}
a:hover,
a:active {
  color: #5A8C99;
}
a.linkButton,
a.callToAction {
  font-family: 'Nexa-Heavy';
  padding: 5px 0px;
  margin: 0px 10px;
  background-color: transparent;
  color: #FFFFFF;
}
a.linkButton:after,
a.callToAction:after {
  display: none;
}
a.icon {
  display: grid;
  width: 35px;
  height: 35px;
  justify-items: center;
  transition: opacity 0.3s ease-out;
}
a.icon:hover {
  opacity: 0.5;
}
a.callToAction {
  padding: 10px 15px 8px 15px;
  background-color: transparent;
  border: solid 2px #FFFFFF;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: border 0.3s ease-out, text 0.3s ease-out;
}
a.callToAction:hover {
  border-color: #3f2d56;
}
a.scopeMissing {
  color: #555;
  opacity: 0.8;
  pointer-events: none;
}
h1 {
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  h1 {
    font-size: 22px;
    line-height: 24px;
  }
}
h2 {
  color: #533474;
  font-size: 1.9em;
  line-height: 1.4em;
  letter-spacing: 0.5px;
}
h1,
h2 {
  font-family: 'Nexa-Heavy';
  color: #3f2d56;
}
h3,
h4 {
  font-family: 'Nexa';
  letter-spacing: 0.2px;
  font-size: 15px;
}
h3 {
  color: #000000;
  font-family: 'NexaText-Bold';
}
h5 {
  color: #CCCCCC;
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: 'Nexa-Heavy';
}
.trigger.close {
  position: relative;
  width: 20px;
  height: 30px;
  text-align: center;
}
.trigger.close:after {
  display: none;
}
.trigger.close span {
  transition: transform 0.3s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 2px;
  margin: auto;
  transform: rotate(45deg);
  background-color: #FFFFFF;
}
.trigger.close span:first-child {
  display: none;
}
.trigger.close span:last-child {
  transform: rotate(-45deg);
}
.transition {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.transition.transition-leave-active {
  opacity: 0;
}
.transition.transition-enter-active {
  opacity: 0;
}
.content-group-trigger {
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  color: #555;
  font-size: 15px;
  line-height: 30px;
  font-family: 'NexaText-Bold';
}
.content-group-trigger.open {
  margin-bottom: 15px;
}
.attribute {
  padding: 0 5px;
  background-color: #CCCCCC;
  color: #FFFFFF;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'NexaText-Bold';
}
.attribute.color1 {
  background-color: #3f2d56;
}
.attribute.red {
  background-color: #EA624F;
}
.attribute .text-full {
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .attribute .text-short {
    display: none;
  }
  .attribute .text-full {
    display: grid;
  }
}
label p.input-description {
  clear: both;
  font-family: 'NexaText-Bold';
  color: #3f2d56;
  font-size: 12px;
  text-align: left;
  border-left: solid 2px #3f2d56;
  padding: 0px 0px 0px 10px;
}
.loading-partial {
  position: fixed;
  display: grid;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}
.loading-partial .content-container {
  padding: 40px;
  text-align: center;
  display: grid;
}
.loading-partial .content-container .animation {
  margin-top: 10px;
  display: grid;
  justify-self: center;
  position: relative;
  width: 150px;
  height: 10px;
  overflow: hidden;
}
.loading-partial .content-container .animation span:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 5px;
  background-color: #3f2d56;
  animation-name: loaderAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
}
@keyframes loaderAnimation {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 20px);
  }
}
.notice {
  clear: both;
  border-left: solid 2px #3f2d56;
  color: #3f2d56;
  font-size: 12px;
  text-align: left;
  font-family: 'NexaText-Bold';
  padding: 0px 0px 0px 10px;
}
label span.label,
.label-replacement span.label {
  font-family: 'Nexa';
  letter-spacing: 0.2px;
  padding: 5px 0px 0px 0px;
  color: #555;
  font-size: 12px;
  text-transform: uppercase;
}
label .description {
  font-size: 12px;
}
label.lilab.checkbox .description {
  clear: both;
  padding-left: 50px;
  text-align: left;
}
label.lilab.checkbox.disabled {
  pointer-events: none;
}
label.lilab.checkbox.disabled input[type="checkbox"] + .label {
  color: #F6F6F6;
}
label.lilab.checkbox.disabled input[type="checkbox"] + .label::after {
  border-color: #F6F6F6;
}
label.lilab.checkbox.disabled input[type="checkbox"]:checked + .label {
  color: #3f2d56;
}
label.lilab.checkbox.disabled input[type="checkbox"]:checked + .label::after {
  border-color: #3f2d56;
}
label.lilab.checkbox:hover input[type="checkbox"] + .label {
  color: #3f2d56;
}
label.lilab.checkbox:hover input[type="checkbox"] + .label::after {
  border-color: #533474;
}
label.lilab.checkbox:hover input[type="checkbox"]:checked + .label {
  color: #3f2d56;
}
label.lilab.checkbox:hover input[type="checkbox"]:checked + .label::after {
  border-color: #3f2d56;
}
fieldset legend {
  padding: 10px 0px;
  font-family: 'Nexa-Heavy';
  position: relative;
  width: 100%;
  text-transform: uppercase;
  color: #555;
}
fieldset legend span {
  padding-right: 10px;
  z-index: 400;
  position: relative;
  display: block;
  float: left;
  max-width: 80%;
  color: #3f2d56;
  background-color: #FFFFFF;
}
fieldset legend:after {
  z-index: 300;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  margin: auto;
  content: '';
  background-color: #3f2d56;
}
label span.required,
.label-parent-container span.required,
.label-replacement span.required {
  font-family: 'Nexa';
  letter-spacing: 0.2px;
  padding: 5px 0px 0px 0px;
  color: #555;
  font-size: 12px;
  text-align: right;
  text-transform: uppercase;
}
.editor .editor-module-container {
  margin: 0px 10px 20px 10px;
  padding: 20px 0px;
}
.editor .editor-module-container.padding {
  padding: 20px;
}
form fieldset {
  display: grid;
  gap: 50px;
  width: 100%;
  padding: 0;
  border: 0;
}
form fieldset legend {
  margin-bottom: 40px;
}
form fieldset legend:after {
  background-color: #533474;
}
form fieldset fieldset legend {
  margin-bottom: 0;
}
form fieldset fieldset legend:after {
  display: none;
}
.mediadetection .tablet,
.mediadetection .desktop,
.mediadetection .wide {
  display: none;
}
.mediadetection .mobile {
  display: block;
}
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .mediadetection .mobile,
  .mediadetection .desktop,
  .mediadetection .wide {
    display: none;
  }
  .mediadetection .tablet {
    display: block;
  }
}
@media only screen and (min-width: 900px) {
  .mediadetection .mobile,
  .mediadetection .tablet,
  .mediadetection .wide {
    display: none;
  }
  .mediadetection .desktop {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .mediadetection .mobile,
  .mediadetection .tablet,
  .mediadetection .desktop {
    display: none;
  }
  .mediadetection .wide {
    display: block;
  }
}
form {
  width: 100%;
  display: grid;
  gap: 40px;
}
.permissionGroup .title {
  display: grid;
  padding-left: 0;
  grid-template-columns: max-content;
}
.permissionGroup label {
  margin-bottom: 20px;
}
.details-partial p {
  margin-top: 20px;
  font-family: 'NexaText-Bold';
  font-size: 12px;
  color: #3f2d56;
  border-left: solid 2px #3f2d56;
  padding: 0px 0px 0px 10px;
}
@keyframes upload {
  0% {
    transform: translateY(75%);
  }
  100% {
    transform: translateY(-75%);
  }
}
label.toggle .state-container {
  display: grid;
  grid-template-columns: max-content max-content;
}
label.toggle .state-container .button {
  margin: 0;
}
.actions-module .single-action {
  display: grid;
  padding: 10px 20px;
  gap: 10px;
}
.actions-module .single-action:hover {
  background-color: #F6F6F6;
}
.actions-module .single-action .action-head {
  display: grid;
  grid-template-columns: auto auto;
  text-transform: uppercase;
}
.actions-module .single-action .action-head .state {
  transition: color 0.3s ease-out;
  text-align: right;
  font-family: 'NexaText-Bold';
}
.actions-module .single-action .action-head .state.started {
  color: #555;
}
.actions-module .single-action .action-head .state.success {
  color: #5A8C99;
}
.actions-module .single-action .action-head .state.error {
  color: #EA624F;
}
.actions-module .single-action p {
  font-size: 12px;
}
.editor-module header {
  background-color: #FFFFFF;
  padding: 0px 20px;
  z-index: 700;
  transition: background 0.3s ease-out;
}
.editor-sub.selection .editor-module header {
  display: grid;
  grid-template-columns: auto 50px;
}
.editor-sub.selection .editor-module header:hover button h1 {
  color: #533474;
}
.editor-module header button {
  line-height: 20px;
  text-align: left;
}
.editor-sub.selection .editor-module header button.move {
  padding: 0;
}
.editor-module header button:hover .indicator {
  color: #e0e5e5;
}
.editor-module header button .indicator,
.editor-module header button .type {
  transition: color 0.3s ease-out;
}
.editor-module header button .indicator {
  overflow: hidden;
  color: #3f2d56;
  font-size: 15px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  font-family: 'Nexa-Heavy';
  line-height: 19.5px;
}
.editor-module header button.move {
  display: grid;
  align-self: end;
  height: 20px;
}
.editor-module header button.move .icon-partial {
  display: grid;
  align-items: center;
  justify-items: end;
  text-indent: 0;
}
.editor-module header button.move .icon-partial svg {
  width: 14px;
  stroke: #555;
  transition: stroke 0.3s ease-out;
}
.editor-module header button.move:hover .icon-partial svg {
  stroke: #3f2d56;
}
.editor-module header button.move.up .icon-partial svg {
  transform: rotate(90deg);
}
.editor-module header button.move.down .icon-partial svg {
  transform: rotate(90deg);
}
.editor-module header button.back {
  background-color: #3f2d56;
  color: #FFFFFF;
  line-height: 24px;
  padding: 0px 10px;
  transition: background 0.3s ease-out;
}
.editor-module header button.back:hover {
  background-color: #533474;
}
.editor-module header div.move {
  display: grid;
  align-content: end;
  justify-content: end;
}
.editor-module header h1 {
  margin-top: 3px;
  font-size: 1.5em;
  transition: color 0.3s ease-out;
}
.open.editor-module header:after {
  transform: rotate(90deg);
}
.editor-module header:after {
  content: '';
  display: table;
  clear: both;
}
.editor-sub h4.head {
  margin-top: 10px;
  padding: 10px 20px 5px 20px;
  display: grid;
  grid-template-columns: auto auto;
  color: #CCCCCC;
  font-size: 12px;
  text-transform: uppercase;
}
.editor-sub h4.head span:last-child {
  text-align: right;
}
.editor-sub label .input-description,
.editor-sub .label-replacement .input-description {
  margin: 10px 0px;
  clear: both;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .editor-sub .content {
    gap: 20px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
    margin: 5px 10px 20px 10px;
  }
}
.editor-sub .drag-options {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  height: 45px;
}
.editor-sub.selection,
.editor-sub.details {
  display: grid;
  overflow: hidden;
}
.editor-sub.selection {
  grid-template-rows: minmax(40px, auto) auto;
}
.editor-sub.selection .content {
  display: grid;
  grid-template-rows: minmax(40px, auto) 115px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .editor-sub.selection .content {
    grid-template-rows: minmax(40px, auto) 45px;
  }
}
.editor-sub.selection .content .component-container {
  overflow-y: auto;
}
.editor-sub.details .editor.open {
  display: grid;
  grid-template-rows: max-content auto;
  gap: 5px;
  overflow: hidden;
  padding-bottom: 70px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .editor-sub.details .editor.open {
    padding-bottom: 0px;
  }
}
.editor-sub.details .editor.open .editor-module-container {
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 25px;
  overflow-y: auto;
  margin: 0;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .editor-sub.details .editor.open .editor-module-container {
    margin: 5px 10px 20px 10px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
  }
}
.editor-sub.details .editor.open .editor-module-container.optionsVisible {
  padding-bottom: 0;
}
.editor-sub.details .editor.open .editor-module-container.noGap {
  gap: 0;
}
.editor-sub.details .editor.open .editor-module-container .editor-content-container {
  padding: 0px 20px;
  display: grid;
  gap: 10px;
}
.editor-sub.details .editor.open .editor-module-container .editor-content-container.noPadding {
  padding: 0;
}
.editor-sub.details .editor.open .editor-module-container .details {
  margin: 0px 0px 30px 0px;
}
.editor-sub.base .content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0;
  overflow-y: auto;
  margin: 0;
  box-shadow: none;
  padding: 0px 20px;
  background-color: #F9F9F9;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .editor-sub.base .content {
    grid-template-columns: max-content max-content max-content;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    background-color: transparent;
  }
}
.editor-sub.base .content .overview header {
  padding: 0px;
  display: grid;
  width: 100%;
}
.editor-sub.base .content .editor-add-editor {
  grid-column-start: 1;
  grid-column-end: 4;
}
.editor-options-editor {
  z-index: 700;
  transition: background 0.3s ease-out;
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  width: 100%;
  height: 60px;
  background-color: #F6F6F6;
  text-align: right;
  padding: 0px 20px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .editor-options-editor {
    position: sticky;
    top: 0;
    bottom: unset;
    align-content: start;
    width: auto;
    height: 50px;
    padding: 0;
    background-color: transparent;
    margin: 0px 10px;
  }
}
.editor-options-editor .left {
  display: grid;
}
.editor-options-editor .right {
  display: grid;
  grid-template-columns: min-content max-content;
  justify-content: end;
}
.editor-options-editor .state,
.editor-options-editor button {
  padding: 0px 15px;
  display: block;
  float: left;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Nexa-Heavy';
}
.editor-options-editor .state {
  height: 35px;
  font-size: 10.5px;
  line-height: 37px;
}
.editor-options-editor .state .icon {
  display: grid;
  align-content: center;
  justify-content: center;
  height: 35px;
}
.editor-options-editor .state .icon svg {
  width: 20px;
  height: 20px;
  fill: #EA624F;
}
.editor-add-editor .editor-module-container {
  padding: 0;
}
.editor-add-editor label {
  padding: 10px 20px 0px 20px;
  margin-bottom: 10px;
}
.editor-add-editor ul li button {
  font-family: 'Nexa';
  letter-spacing: 0.2px;
  font-size: 15px;
  line-height: 19.5px;
  padding: 10px 20px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  text-align: left;
}
.editor-content-group {
  position: relative;
  padding: 0px 20px;
}
.editor-content-group .editor-content-group .content-group-trigger {
  font-size: 12px;
}
.editor-content-group:hover:before {
  background-color: #533474;
}
.editor-content-group .editor-content-group {
  padding: 0;
}
.editor-content-group.viewContent {
  margin-bottom: 50px;
}
.editor-history header {
  display: grid;
  grid-template-columns: 60% 40%;
}
.editor-history header h3 {
  text-align: right;
}
.editor-history .details {
  padding: 10px 20px;
}
.editor-history .controls {
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.editor-history .controls a {
  text-align: center;
}
.editor-history .options {
  padding: 0px 20px;
}
.editor-history .options li {
  margin-bottom: 40px;
}
.editor-history .options li a {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.editor-history .options li p {
  font-size: 12px;
  margin-top: 5px;
}
.editor-screen .overlay-screen {
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  z-index: 600;
}
.editor-screen .overlay-screen:empty {
  display: none;
}
.editor-container .component-container {
  display: grid;
}
.editor-container .component-container:hover .position-container {
  opacity: 0.4;
}
.editor-container .component-container .position-container {
  display: grid;
  transition: opacity 0.3s ease-out;
}
.editor-container .component-container .position-container + .position-container {
  position: relative;
  width: 100%;
}
.editor-container .component-container .position-container:hover {
  opacity: 1;
}
.editor-container .component-container .position-container:hover h3 {
  color: #555;
}
.editor-container .component-container .position-container .sort-element {
  transition: transform ease 0.1s, opacity ease 0.1s;
}
.editor-container .component-container .position-container .sort-element.sort-list-enter-active,
.editor-container .component-container .position-container .sort-element.sort-list-leave-active {
  opacity: 0.3;
}
.editor-container .component-container .position-container .sort-element.sort-list-enter-active,
.editor-container .component-container .position-container .sort-element.sort-list-leave-active {
  transform: scale(0.98);
}
.editor-container .component-container .position-container .sort-element.sort-list-leave-active {
  position: absolute;
}
.editor-container .component-container .position-container .container-head {
  position: sticky;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #FFFFFF;
  z-index: 500;
}
.editor-container .component-container .position-container .container-head h3 {
  transition: color 0.3s ease-out;
  padding: 0px 20px;
  align-self: start;
  justify-self: start;
  height: 40px;
  background-color: #FFFFFF;
  color: #CCCCCC;
  font-size: 10.5px;
  line-height: 40px;
}
.editor-container .component-container .position-container .container-head h3:nth-child(2) {
  justify-self: end;
}
.input.search:focus {
  background: inherit;
}
.input.search .inputContainer {
  position: relative;
  display: grid;
  gap: 5px;
}
.input.search .inputContainer .position-container .add-notice {
  position: absolute;
  right: 0;
  bottom: 7px;
  margin: auto;
}
.list-editor.text ul li {
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  min-height: 40px;
}
.list-editor.text ul li .text-element {
  display: grid;
  align-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 15px;
  text-transform: uppercase;
  text-overflow: ellipsis;
}
.list-editor.text ul li .text-element.hasDescription .id,
.list-editor.text ul li .text-element.hasDescription .description {
  display: grid;
}
.list-editor.text ul li .text-element.hasDescription .description {
  font-size: 12px;
  text-transform: none;
}
.list-editor.text ul li .list-item-options {
  grid-template-columns: 30px 30px 30px;
}
.list-editor.text ul li .list-item-options:before {
  display: none;
}
.list-editor.text.input .list-item-options {
  z-index: 300;
  position: absolute;
  right: 0;
  bottom: 0;
}
.list-editor ul li .list-item-options {
  position: relative;
  display: grid;
  z-index: 100;
}
.list-editor ul li .list-item-options button {
  display: grid;
  width: 40px;
  height: 35px;
  font-size: 15px;
  line-height: 35px;
  text-align: left;
  text-transform: uppercase;
}
.list-editor ul li .list-item-options button.elementDescription {
  width: auto;
  padding: 0px 20px;
}
.list-editor ul li .list-item-options button.elementDescription:hover {
  color: #3f2d56;
}
.list-editor ul li .list-item-options button.hasImage {
  width: calc(100% - 40px);
  height: 120px;
  margin-left: 20px;
  padding: 0px;
}
.list-editor ul li .list-item-options button.hasImage:hover {
  background-color: #3f2d56;
}
.list-editor ul li .list-item-options button.hasImage picture {
  max-width: 100%;
  height: 120px;
  line-height: 40px;
}
.list-editor ul li .list-item-options button.hasImage picture img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}
.list-editor h4 {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 10px 0px;
}
.list-editor ul {
  margin-bottom: 10px;
}
.list-editor ul li {
  margin-bottom: 5px;
}
.list-editor ul li label {
  position: relative;
  margin: 0;
  padding: 0;
}
.list-editor ul li label.input-padding {
  padding: auto;
  padding: 0px 20px;
  margin-bottom: 20px;
}
.media.screen .scroll-container {
  overflow-y: auto;
  padding: 0;
}
.media.screen .dropfield {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0px 40px 100px 40px;
}
.media.screen .dropfield:after {
  content: '';
  flex-grow: 1e4;
  min-width: 20%;
}
.media.screen .dropfield .item {
  position: relative;
  display: grid;
  overflow: hidden;
  margin: 2px;
  background-color: #3f2d56;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}
.media.screen .dropfield .item::after {
  content: '';
  flex-grow: 999999999;
}
.media.screen .dropfield .item:hover {
  opacity: 0.8;
}
.media.screen .dropfield .item .video-placeholder {
  position: absolute;
  display: grid;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  z-index: 100;
  font-family: 'Nexa-Heavy';
}
.media.screen .dropfield .item.folder {
  display: grid;
}
.media.screen .dropfield .item.folder .icon-partial {
  position: absolute;
  display: grid;
  align-self: center;
  justify-self: center;
  z-index: 100;
}
.media.screen .dropfield .item i {
  display: block;
}
.media.screen .dropfield .item .infos {
  position: absolute;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
.media.screen .dropfield .item .infos .typeSize {
  display: grid;
  grid-template-columns: max-content max-content;
  align-self: end;
  justify-content: end;
  width: 100%;
}
.media.screen .dropfield .item .infos .typeSize span {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.media.screen .dropfield .item .infos .filename {
  display: grid;
  grid-template-rows: min-content;
  justify-self: start;
}
.media.screen .dropfield .item .infos .filename span {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.media.screen .dropfield .item .infos span {
  display: inline-block;
  background-color: #3f2d56;
  color: #FFFFFF;
  padding: 5px 10px;
}
.media.screen .dropfield .item .infos span:last-child {
  justify-self: end;
}
.media.screen .dropfield .item .selectButton {
  position: absolute;
  top: 5px;
  left: 5px;
}
.media.screen .dropfield .item.image {
  opacity: 1;
  background-color: #FFFFFF;
  transition: background 0.3s ease-out;
}
.media.screen .dropfield .item.image:hover {
  background-color: rgba(63, 45, 86, 0.8);
}
.media.screen .dropfield .item picture {
  transition: opacity 0.5s ease-out;
  transition-delay: 0.5s;
  mix-blend-mode: multiply;
}
.media.screen .dropfield .item picture img {
  position: absolute;
  top: 0;
  vertical-align: bottom;
  width: 100%;
}
.media.screen .dropfield .item picture.svg img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 40px;
  max-width: 50%;
  margin: auto;
}
.media.screen .dropfield .item video,
.media.screen .dropfield .item .placeholder {
  position: absolute;
  top: 0;
  vertical-align: bottom;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.media.files {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  overflow: hidden;
}
body {
  background: #FFFFFF;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Nexa';
  letter-spacing: 0.2px;
  font-size: 11.5px;
}
body.overlay,
body.fullscreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
body #preload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  transition: opacity 0.3s ease-out;
}
body.loading #preload {
  pointer-events: all;
  opacity: 1;
}
.screen {
  display: block;
}
.screen.main {
  padding-top: 35px;
}
.screen.main header.main {
  padding-bottom: 20px;
}
.inited .screen {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.inited .screen.started {
  opacity: 0;
  transform: translateX(50px);
}
.inited .screen.resolving,
.inited .screen.loading {
  opacity: 0;
  transform: translateX(-50px);
}
.inited .screen.no-navigation-animation {
  opacity: 1;
  transform: none;
}
.inited .contentUpdate {
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
}
.inited .contentUpdate.started {
  opacity: 0;
  transform: translateY(5px);
}
.inited .contentUpdate.resolving {
  opacity: 0;
  transform: translateY(-5px);
}
.screen.editor-screen {
  display: grid;
  grid-template-columns: calc(100vw - 40px) calc(100vw - 40px);
  overflow: hidden;
  width: 200vw;
  height: 100vh;
}
.screen.editor-screen .content-left-container,
.screen.editor-screen .content-right-container {
  position: relative;
  transition: transform 0.1s ease-out;
}
.screen.editor-screen .content-left-container .mobile-overlay,
.screen.editor-screen .content-right-container .mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 0.6;
  z-index: 800;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}
.screen.editor-screen .content-left-container .mobile-overlay {
  pointer-events: none;
  opacity: 0;
}
.screen.editor-screen .content-right-container .mobile-overlay {
  pointer-events: all;
}
.screen.editor-screen.showEditor .content-left-container,
.screen.editor-screen.showEditor .content-right-container {
  transform: translateX(calc(-100% + 40px));
}
.screen.editor-screen.showEditor .content-left-container .mobile-overlay {
  opacity: 0.6;
  pointer-events: all;
}
.screen.editor-screen.showEditor .content-right-container .mobile-overlay {
  opacity: 0;
  pointer-events: none;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .screen.editor-screen {
    grid-template-columns: auto 380px;
    gap: 0 20px;
    width: unset;
  }
  .screen.editor-screen .toggleEditorButton {
    display: none;
  }
  .screen.editor-screen.showEditor .content-left-container,
  .screen.editor-screen.showEditor .content-right-container {
    transform: translateX(0);
  }
  .screen.editor-screen .content-left-container .mobile-overlay,
  .screen.editor-screen .content-right-container .mobile-overlay {
    display: none;
  }
}
.screen.editor-screen .editor-container.base {
  display: grid;
  grid-template-rows: max-content minmax(200px, auto) max-content;
  overflow: hidden;
}
.screen.editor-screen .editor-container.base.details {
  grid-template-rows: minmax(200px, auto) max-content;
}
.screen.editor-screen .editor-container.top {
  height: 35px;
  z-index: 600;
}
.screen.editor-screen .editor-container.top .top-left {
  width: 100%;
}
.screen.editor-screen .content-left-container,
.screen.editor-screen .content-right-container {
  height: calc(100vh - 40px);
}
.screen.editor-screen .content-left-container {
  position: relative;
  display: grid;
  grid-template-rows: 35px 1fr;
}
.screen.editor-screen .content-left-container .top {
  grid-template-columns: 1fr;
  padding: 0px 20px;
  background-color: #F6F6F6;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .screen.editor-screen .content-left-container .top {
    padding: 0px;
    background-color: transparent;
  }
}
.screen.editor-screen .content-left-container .iframe-container {
  display: grid;
  overflow: auto;
}
.screen.editor-screen .content-left-container .iframe-container iframe {
  display: block;
  align-self: center;
  width: 100%;
  height: 100%;
  margin: auto;
  border: 0;
  transition: width 0.3s ease, height 0.3s ease;
}
.screen.editor-screen .content-left-container .iframe-container iframe.mobile {
  width: 360px;
  height: 640px;
}
.screen.editor-screen .content-left-container .iframe-container iframe.tablet {
  width: 768px;
  height: 1024px;
}
.screen.editor-screen .content-left-container .iframe-container iframe.desktop {
  width: 1280px;
  height: 768px;
}
.screen.editor-screen .content-left-container .iframe-container iframe.hd {
  width: 1920px;
  height: 1280px;
}
.screen.editor-screen .content-left-container .iframe-container iframe.mobile,
.screen.editor-screen .content-left-container .iframe-container iframe.tablet,
.screen.editor-screen .content-left-container .iframe-container iframe.desktop,
.screen.editor-screen .content-left-container .iframe-container iframe.hd {
  border: solid 2px #CCCCCC;
  box-shadow: 0 0 5px #CCCCCC;
}
.screen.editor-screen.historyMode .content-left-container .iframe-container {
  position: relative;
}
.screen.editor-screen.historyMode .content-left-container .iframe-container iframe.current {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}
.screen.editor-screen.historyMode .content-left-container .iframe-container iframe.current.visible {
  opacity: 1;
  pointer-events: all;
}
.screen.editor-screen .content-right-container {
  display: grid;
  grid-template-rows: auto;
  align-content: start;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .screen.editor-screen .content-right-container {
    max-width: 360px;
  }
}
.pagenator.full table {
  display: grid;
}
.pagenator.full table.content {
  padding-bottom: 80px;
}
.pagenator.full table.isSticky {
  position: sticky;
  top: 0;
  border-spacing: 0;
  background-color: #F9F9F9;
}
.pagenator.full table tr td {
  display: grid;
  align-content: center;
  height: 75px;
  text-align: center;
}
@media only screen and (min-width: 900px) {
  .pagenator.full table tr td.info {
    grid-column-start: auto;
  }
  .pagenator.full table tr td.last {
    grid-column-start: auto;
  }
  .pagenator.full table tr td.optional {
    display: block;
  }
}
.pagenator.full table tr td.optional {
  display: none;
}
.pagenator.full table tr td.last {
  grid-column-start: end;
}
.pagenator.full table tr td.action {
  grid-template-columns: 1fr;
}
.pagenator.full table tr td.action a {
  display: grid;
  align-content: center;
  width: 100%;
  height: 75px;
}
.pagenator.full table tr td.action.hasDescription a {
  gap: 3px;
  line-height: 16px;
  white-space: nowrap;
}
.pagenator.full table tr td.action.hasDescription a .description,
.pagenator.full table tr td.action.hasDescription a .name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.pagenator.full table tr td.action.hasDescription a .description {
  color: #3f2d56;
}
.pagenator.full table tr td.action.hasDescription a .name {
  color: #CCCCCC;
}
.pagenator.full table tr td.action.hasAttribute a div {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 5px;
}
.pagenator.full table thead tr td {
  height: 30px;
}
.pagenator.full table tbody tr:hover td {
  background-color: #F6F6F6;
}
.pagenator.full table tbody td a {
  padding: 0;
}
.pagenator.full table tbody td a sup {
  display: inline-block;
  padding-right: 2px;
  padding-left: 2px;
  color: #FFFFFF;
  line-height: 20px;
  margin-left: 5px;
}
.pagenator.full table tbody td a sup.missing {
  background-color: #EA624F;
}
.pagenator.full table tbody td a sup.new {
  background-color: #3f2d56;
}
.pagenator.full table,
.pagenator.full .stats {
  width: 100%;
  max-width: 100%;
}
.pagenator.full header.main {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .pagenator.full header.main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.pagenator.full header.main .stats {
  display: grid;
}
.pagenator.full header.main .stats.upload {
  display: grid;
  grid-template-columns: max-content 1fr;
}
.pagenator.full header.main .stats.upload h3 {
  text-align: right;
}
.pagenator.full header.main .stats h3 {
  font-size: 12px;
  text-transform: uppercase;
}
.pagenator.full table {
  display: grid;
  width: 100%;
  margin: 0;
  padding: 0;
}
.pagenator.full table.isSticky {
  position: sticky;
  top: 40px;
  left: 0;
  border-spacing: 0;
  background-color: #F9F9F9;
}
.pagenator.full table thead tr,
.pagenator.full table tbody tr {
  display: grid;
  gap: 5px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .pagenator.full table thead tr,
  .pagenator.full table tbody tr {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 899px), only screen and (min-width: 900px) {
  .pagenator.full table thead tr,
  .pagenator.full table tbody tr {
    gap: 40px;
  }
}
.pagenator.full table thead tr:hover,
.pagenator.full table tbody tr:hover {
  background-color: #F6F6F6;
}
.pagenator.full table thead tr td,
.pagenator.full table tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pagenator.full table thead tr td:last-child,
.pagenator.full table tbody tr td:last-child {
  justify-content: end;
  text-align: right;
}
.pagenator.full table thead tr td:first-child,
.pagenator.full table tbody tr td:first-child {
  justify-content: start;
  text-align: left;
}
.pagenator.full table thead tr td.center,
.pagenator.full table tbody tr td.center {
  justify-items: center;
}
.pagenator.full table thead tr td:first-child,
.pagenator.full table tbody tr td:first-child {
  padding-left: 0px;
}
.pagenator.full table thead tr td:last-child,
.pagenator.full table tbody tr td:last-child {
  padding-right: 0px;
}
.pagenator.full table thead tr td {
  height: 30px;
}
.pagenator.full table tbody tr td {
  height: 75px;
}
.pagenator.full table tbody tr:hover td {
  background-color: #F6F6F6;
}
.pagenator.full table tbody tr td span.name,
.pagenator.full table tbody tr td span.fileInfos {
  display: grid;
  word-break: break-all;
  align-content: center;
}
.pagenator.full table tbody tr td span.fileInfos {
  margin-top: 5px;
  color: #CCCCCC;
}
.screen.started  .pagenator.full table.data-switch.started,
.screen.resolving  .pagenator.full table.data-switch.started,
.screen.done  .pagenator.full table.data-switch.started,
.screen.started  .pagenator.full table.data-switch.resolving,
.screen.resolving  .pagenator.full table.data-switch.resolving,
.screen.done  .pagenator.full table.data-switch.resolving,
.screen.started  .pagenator.full table.data-switch.loading,
.screen.resolving  .pagenator.full table.data-switch.loading,
.screen.done  .pagenator.full table.data-switch.loading {
  opacity: 1;
  transform: none;
}
.pagination header {
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.media.screen {
  height: calc(100vh - 40px);
  overflow: hidden;
}
.media.screen.overlay-screen {
  height: 100%;
  display: none;
}
.overlay-screen .media.screen {
  height: 100%;
}
.data-details table.history tbody tr {
  grid-template-columns: 1fr 2fr 1fr min-content;
}
.data-details table.history tbody tr td {
  padding: 0;
  line-height: 40px;
  height: 40px;
  background-color: transparent;
}
.data-details table.history tbody tr td:first-child {
  white-space: nowrap;
}
.data-details table.history tbody tr td a {
  line-height: 40px;
  padding: 0;
}
table {
  width: 100%;
  border-spacing: 0;
}
table.isSticky {
  background-color: #F9F9F9;
}
table.inline {
  display: grid;
}
table.inline thead,
table.inline tbody {
  display: grid;
  gap: 5px;
}
table.inline thead tr,
table.inline tbody tr {
  display: grid;
  padding: 0;
}
table.inline thead tr:hover,
table.inline tbody tr:hover {
  background-color: transparent;
}
table.inline thead tr:hover td,
table.inline tbody tr:hover td {
  background-color: transparent;
}
table.inline thead tr td,
table.inline tbody tr td {
  height: auto;
  padding: 0;
}
table.inline thead tr td:first-child,
table.inline tbody tr td:first-child,
table.inline thead tr td:last-child,
table.inline tbody tr td:last-child {
  padding: 0;
}
table thead tr,
table tbody tr {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}
table thead tr:hover,
table tbody tr:hover {
  background-color: #F6F6F6;
}
table thead tr td,
table tbody tr td,
table thead tr th,
table tbody tr th {
  padding-top: 10px;
  padding-bottom: 10px;
}
table thead tr td:last-child,
table tbody tr td:last-child,
table thead tr th:last-child,
table tbody tr th:last-child {
  text-align: right;
}
table thead tr td:first-child,
table tbody tr td:first-child,
table thead tr th:first-child,
table tbody tr th:first-child {
  text-align: left;
}
table thead tr td.fullHeight,
table tbody tr td.fullHeight,
table thead tr th.fullHeight,
table tbody tr th.fullHeight {
  padding-top: 0px;
  padding-bottom: 0px;
}
table thead tr td.fullHeight .full,
table tbody tr td.fullHeight .full,
table thead tr th.fullHeight .full,
table tbody tr th.fullHeight .full {
  display: block;
  line-height: 60px;
}
table thead tr td:first-child,
table tbody tr td:first-child,
table thead tr th:first-child,
table tbody tr th:first-child {
  padding-left: 40px;
}
table thead tr td:last-child,
table tbody tr td:last-child,
table thead tr th:last-child,
table tbody tr th:last-child {
  padding-right: 40px;
}
table thead tr td {
  height: 40px;
}
table tbody tr td {
  height: 60px;
}
table tbody tr:hover td {
  background-color: #F6F6F6;
}
.project-overview-screen .pagenator.full table tr {
  grid-template-columns: 10fr 5fr;
}
.project-overview-screen .pagenator.full table tr td:last-child {
  text-align: left;
}

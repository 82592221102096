.contentUpdate {

  .inited & {

    transition: opacity .15s @aType, transform .15s @aType;

    // &.content-leave-active,

    &.started {
      opacity: 0;
      transform: translateY(5px);
    }

    &.resolving {
      opacity: 0;
      transform: translateY(-5px);
    }

    // &.content-enter-active {
    //   opacity: 0;
    //   transform: translateY(-5px);
    // }

  }

}
.editor-screen {
    .overlay-screen {

        // opacity: 0;
        // &.done {
        //     opacity: 1;
        // }

        .trans(opacity);

        &:empty {
            display: none;
        }

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: @white;

        .index(6);
    }
}
.transition {
    transition: opacity .5s ease, transform .5s ease;

    &.transition-leave-active {
        opacity: 0;
    }
    
    &.transition-enter-active {
        opacity: 0;
    }
}

.notice {

  clear: both;

  border-left: solid 2px @color1;
  color: @color1;
  font-size: @fontTextSmaller;
  text-align: left;

  .font-bold;

  .multi(padding, 0, 0, 0, 2);

}
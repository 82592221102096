.editor-content-group {

    position: relative;
    .multi(padding, 0, 4);
    
    .editor-content-group & {
        .content-group-trigger {
            font-size: @fontTextSmaller;
        }
    }

    // .description {
    //     .multi(padding, 0, 0, 4, 0);
    // }
    
    &:hover {
        &:before {
            background-color: @color3;
        }
    }

    .editor-content-group  {

        padding: 0;

    }

    &.viewContent {

        .multi(margin-bottom, 10);

        
    }

}
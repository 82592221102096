label {
    .description {
        font-size: @fontTextSmaller;
    }


    &.lilab.checkbox {
        .description {
            clear: both;
            .multi(padding-left, 10);
            text-align: left;
        }


        &.disabled {
            pointer-events: none;

            input[type="checkbox"] {
                &+.label {
                    color: @grey1;

                    &::after {
                        border-color: @grey1;
                    }
                }

                &:checked {
                    &+.label {
                        color: @color1;

                        &::after {
                            border-color: @color1;
                        }
                    }
                }
            }

        }

        &:hover {
            input[type="checkbox"] {
                &+.label {
                    color: @color1;

                    &::after {
                        border-color: @color3;
                    }
                }

                &:checked {
                    &+.label {
                        color: @color1;

                        &::after {
                            border-color: @color1;
                        }
                    }
                }
            }

        }

    }
}
.font("Nexa-Heavy", "Nexa-Heavy");
.font("NexaText-Bold", "NexaText-Bold");
.font("Nexa", "Nexa");
.font("FiraMono-Regular", "FiraMono");

.font-light {
  font-family: Nexa;
}

.font-normal {
  font-family: 'Nexa';
  letter-spacing: .2px;
}

.font-bold {
  font-family: 'NexaText-Bold';
}

.font-head {
  font-family: 'Nexa-Heavy';
}

.font-mono {
  font-family: FiraMono;
}
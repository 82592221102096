.permissionGroup {

  .title {
    display: grid;
    padding-left: 0;
    grid-template-columns: max-content

  }

  label {
    .multi(margin-bottom, 4);
  }
}
.editor-add-editor {
    .editor-module-container {
        padding: 0;
    }

    label {
        .multi(padding, 2, 4, 0, 4);
        .multi(margin-bottom, 2);
    }

    ul {
        li {
            button {
                .font-normal;

                font-size: @fontText;
                .lineHeight(@fontText);

                .multi(padding, 2, 4);

                text-transform: uppercase;
                display: block;

                width: 100%;
                text-align: left;
            }
        }
    }

}
fieldset legend {
  .multi(padding, 2, 0);
  .font-head;

  position: relative;

  width: 100%;

  text-transform: uppercase;

  color: @textColor;

  span {
    .multi(padding-right, 2);
    .index(4);

    position: relative;

    display: block;
    float: left;

    max-width: 80%;

    color: @color1;
    background-color: @white;
  }

  &:after {
    .index(3);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;
    margin: auto;

    content: '';

    background-color: @color1;
  }
}
.details-partial {
    p {
        .multi(margin-top, 4);
        .font-bold;
        font-size: @fontTextSmaller;
        color: @color1;

        border-left: solid 2px @color1;

        .multi(padding, 0, 0, 0, 2);
    }
}
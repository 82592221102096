.list-editor {

  h4 {
    text-transform: uppercase;
    .multi(margin-bottom, 2);
    .multi(padding, 2, 0);
  }

  ul {

    .multi(margin-bottom, 2);

    li {

      .multi(margin-bottom, 1);

      label {
        position: relative;
        margin: 0;

        padding: 0;

        &.input-padding {
          padding: auto;
          .multi(padding, 0, 4);
          .multi(margin-bottom, 4);
        }
      }

    }

  }
}
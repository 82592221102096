body {
  background: @white;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  .font-normal;
  font-size: 11.5px;

  &.overlay,
  &.fullscreen {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  #preload {

    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    pointer-events: none;
    opacity: 0;

    display: grid;
    justify-content: center;
    align-content: center;

    .trans(opacity);
  }

  &.loading {
    #preload {
      pointer-events: all;
      opacity: 1;
    }

  }


}
.editor {

    .editor-module-container {

        .multi(margin, 0, 2, 4, 2);
        .multi(padding, 4, 0);
        
        &.padding {
            .multi(padding, 4);
        }

    }
    
}
.screen {
  display: block;

  &.main {
    .multi(padding-top, 7);

    header.main {
      .multi(padding-bottom, 4);
    }
  }

  .inited & {
    transition: opacity .5s @aType, transform .5s @aType;

    &.started {
      opacity: 0;
      transform: translateX(50px);
    }

    &.resolving, &.loading {
      opacity: 0;
      transform: translateX(-50px);
    }

    &.no-navigation-animation {
      opacity: 1;
      transform: none;
    }

  }

}
form fieldset {
  display: grid;
  gap: 50px;
  width: 100%;
  padding: 0;
  border: 0;

  legend {
    .multi(margin-bottom, 8);

    &:after {
      background-color: @color3;
    }
  }

  fieldset {

    legend {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }
}
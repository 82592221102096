.list-editor {

  &.text {

    ul li {
      position: relative;
      display: grid;
      grid-template-columns: 1fr min-content;
      min-height: 40px;

      .text-element {
        display: grid;
        align-content: center;
        align-items: center;
        overflow: hidden;
        font-size: @fontText;
        text-transform: uppercase;
        text-overflow: ellipsis;

        &.hasDescription {

          .id, .description {
            display: grid;
          }

          .description {
            font-size: @fontTextSmaller;
            text-transform: none;
          }

        }
      }

      .list-item-options {

        grid-template-columns: 30px 30px 30px;

        &:before {
          display: none;
        }
      }
    }

    &.input {

      .list-item-options {
        .index(3);

        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  ul {

    li {

      .list-item-options {
        position: relative;
        display: grid;
        .index(1);

        button {

          display: grid;
          width: 40px;
          height: 35px;
          font-size: @fontText;
          line-height: 35px;

          text-align: left;
          text-transform: uppercase;

          &.elementDescription {
            width: auto;
            .multi(padding, 0, 4);

            &:hover {
              color: @color1;
            }
          }

          // .icon {
          //   display: grid;
          //   text-indent: 0;

          //   svg {
          //     align-self: center;
          //     justify-self: center;
          //     width: 10px;

          //     height: 10px;

          //     .trans(fill);
          //   }

          // }

          // &.up {
          //   transform: rotate(90deg);
          // }

          // &.down {
          //   transform: rotate(90deg);
          // }

          // &:hover {

          //   .icon {

          //     svg {
          //       fill: @color2;
          //     }
          //   }
          // }

          &.hasImage {
            width: calc(100% - 40px);

            height: 120px;
            margin-left: 20px;
            .multi(padding, 0);

            &:hover {
              background-color: @color1;
            }

            picture {
              max-width: 100%;
              height: 120px;

              line-height: 40px;

              img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                mix-blend-mode: multiply;
              }

            }

          }
        }

      }
    }
  }
}
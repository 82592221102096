label.toggle {

    .state-container {
        display: grid;
        grid-template-columns: max-content max-content;

        .button {
            margin: 0;

        }

    }

}
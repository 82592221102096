.editor-module {

  header {

    background-color: @white;
    .multi(padding, 0, 4);

    .index(7);

    .editor-sub.selection & {
      display: grid;
      grid-template-columns: auto 50px;

      &:hover {

        button {

          h1 {
            color: @color3;
          }

        }

      }
    }

    button {

      line-height: 20px;

      text-align: left;

      .editor-sub.selection & {

        &.move {
          padding: 0;
        }
      }

      &:hover {

        .indicator {
          color: @color2;
        }

      }

      .indicator,
      .type {
        .trans(color);
      }

      .indicator {
        overflow: hidden;
        color: @color1;

        font-size: @fontText;
        text-transform: uppercase;

        text-overflow: ellipsis;

        .font-head;
        .lineHeight(@fontText);

      }

      &.move {

        display: grid;
        align-self: end;

        height: 20px;

        .icon-partial {
          display: grid;
          align-items: center;
          justify-items: end;

          text-indent: 0;

          svg {
            width: 14px;
            stroke: @textColor;

            .trans(stroke);
          }

        }

        &:hover {

          .icon-partial {

            svg {
              stroke: @color1;
            }
          }
        }

        &.up {

          .icon-partial {

            svg {
              transform: rotate(90deg);

            }
          }
        }

        &.down {

          .icon-partial {

            svg {
              transform: rotate(90deg);
            }
          }

        }

      }

      &.back {
        background-color: @color1;
        color: @white;
        .lineHeight(@fontTextSmaller, 2);

        .multi(padding, 0, 2);

        .trans(background);

        &:hover {
          background-color: @color3;
        }
      }

    }

    div.move {
      display: grid;
      align-content: end;
      justify-content: end;
    }

    h1 {
      margin-top: 3px;
      font-size: 1.5em;

      .trans(color);
    }

    .trans(background);

    .open& {

      &:after {
        transform: rotate(90deg);
      }

    }

    .clearfix();
  }
}
.data-details {
    table {
        &.history {
            tbody {
                tr {
                    grid-template-columns: 1fr 2fr 1fr min-content;

                    td {
                        padding: 0;

                        &:first-child {
                            white-space: nowrap;
                        }

                        line-height: 40px;
                        height: 40px;
                        background-color: transparent;

                        a {
                            line-height: 40px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
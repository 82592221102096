
.font(@font) {

  @font-face {
    font-family: '@{font}';
    src: url('@{fontBase}@{font}.woff');
  }
}

.font(@font, @name) {

  @font-face {
    font-family: '@{name}';
    src: url('@{fontBase}@{font}.woff');
  }
}

.multi(@type, @multi) {
  @{type}: @multi * 5px;
}

.multi(@type, @top, @sides) {
  @{type}: @top * 5px @sides * 5px;
}

.multi(@type, @top, @right, @bottom, @left) {
  @{type}: @top * 5px @right * 5px @bottom * 5px @left * 5px;
}

.index(@a) {
  z-index: @a * 100;
}
.trans(@type) {
  transition: @type @aTime @aType;
}
.lineHeight(@fontSize, @multi: 1.3) {
  line-height: @fontSize * @multi;
}

.clearfix {

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.formBorder {
  border: 0;
  border-bottom: 1px solid @color1;
}
.margin(@a, @dir) when (@dir = "right") {
  margin-right: @a * @margin;
}

.margin(@a, @dir) when (@dir = "left") {
  margin-left: @a * @margin;
}

.margin(@a, @dir) when (@dir = "bottom") {
  margin-bottom: @a * @margin;
}

.column(@a) {
  width: @a * @column;
}

.basicHover {
  .trans(opacity);

  &:hover {
    opacity: .5;
  }
}
.backgroundGradient(@type, @from, @to) {
  background: linear-gradient(@type, @from, @to);
}
.padding(@a, @dir) when (@dir = "right") {
  padding-right: @a * @margin;
}

.padding(@a, @dir) when (@dir = "left") {
  padding-left: @a * @margin;
}

.padding(@a, @dir) when (@dir = "bottom") {
  padding-bottom: @a * @margin;
}

.basePadding {
  padding-right: 20px;
  padding-left: 20px;

  @media @tablet, @desktop {
    padding-right: 40px;
    padding-left: 40px;
  }

}

.basePaddingWithTop {
  padding: 20px;

  @media @tablet, @desktop {
    padding-right: 40px;
    padding-left: 40px;
  }

}
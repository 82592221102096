label {

    p.input-description {

        clear: both;

        .font-bold;
        color: @color1;
        font-size: @fontTextSmaller;
        text-align: left;

        border-left: solid 2px @color1;

        .multi(padding, 0, 0, 0, 2);
        // .multi(margin, 2, 0);

    }

}
.project-overview-screen {

  .pagenator.full {

    table {

      tr {
        grid-template-columns: 10fr 5fr;

        td {

          &:last-child {
            text-align: left;
          }
        }
      }

    }
  }


}